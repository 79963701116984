import { Serializable } from "@/core/generated/api/decorators/serializable_decorator"

@Serializable
export class DownloadCVArgs {
  cv: string = ""
  user: string = ""
  lang: string = ""
  filetype: string = ""
  template?: string = ""

  constructor(data: Partial<DownloadCVArgs>) {
    Object.assign(this, data)
  }
}
