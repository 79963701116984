import { ChangeEvent, useEffect, useState } from "react"

interface IPercentageInput {
  value: number
  onChange?(percentage: number): void
  changeable?: boolean
  readOnly?: boolean
  required?: boolean
  placeholder?: string
  shouldUpdate?: number
  onBlur?(percentage: number): void
}

export const PercentageInput = ({
  readOnly = false,
  changeable = true,
  value = 0,
  required = false,
  placeholder = "",
  shouldUpdate,
  onChange,
  onBlur = null,
}: IPercentageInput) => {
  const [input, setInput] = useState<string>("0")

  useEffect(() => {
    setInput(String(value))
  }, [value, shouldUpdate])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9.]/g, "")
    let result = parseFloat(value)
    result = isNaN(result) ? 0 : result
    onChange?.(result)
    setInput(String(value))
  }

  const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
    let result = parseFloat(e.target.value.replace(/[^0-9.]/g, ""))
    result = isNaN(result) ? 0 : result
    onBlur?.(result)
  }

  return (
    <>
      {readOnly ? (
        <div className="relative after:pl-px after:content-['%']">{input}</div>
      ) : (
        <>
          <div className="relative flex h-7 w-12">
            <input
              type="text"
              placeholder={placeholder}
              value={input}
              disabled={!changeable}
              required={required}
              className={`after:content w-12 px-2 focus:border-blue-700`}
              onChange={handleChange}
              onBlur={handleOnBlur}
            />
            <p className="absolute right-1 top-1/2 -translate-y-1/2 select-none">
              %
            </p>
          </div>
        </>
      )}
    </>
  )
}
