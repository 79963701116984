import { selectedSpaceAtom } from "@/atoms/spaces"
import { IPricing } from "@/features/candidateflow/types"
import { DropdownGeneric } from "@/shared/components/fields"
import { MoneyInput, formatValue } from "@/shared/components/inputs"
import { DropdownItem } from "@/shared/components/items/DropdownItem"
import { createSubpricing, updatePricing } from "@/utils/api/pricings"
import {
  faCheck,
  faTrashCan,
} from "@awesome.me/kit-44b29310a6/icons/classic/regular"
import {
  faCaretDown,
  faCaretUp,
} from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { debounce } from "lodash"
import { useRecoilValue } from "recoil"
import { toast } from "sonner"
import { PercentageInput } from "."
import { formatTypeValues } from "../../../shared/utils/helper"
import { AddTagButton } from "../../tags/components/AddTagButton"
import { TagsViewer } from "../../tags/components/TagsViewer"

interface IPricingsRow {
  item: IPricing
  parent?: IPricing
  tags?: Record<string, any>[]
  onChange(pricing: Partial<IPricing & { isNew: boolean }>): void
  tagsUpdated?(): void
  readOnly?: boolean
  supplier?: string
  remove(uid: string): void
}

export const PricingRow = ({
  item,
  parent,
  tags,
  onChange,
  tagsUpdated,
  readOnly,
  supplier,
  remove,
}: IPricingsRow) => {
  const space = useRecoilValue(selectedSpaceAtom)

  const handleChange = async (price: Partial<IPricing>) => {
    try {
      const { uid, ...rest } = price
      const newPrice = rest.price - (parent?.price ?? 0)
      const percentage = rest.percentage - (parent?.percentage ?? 0)
      const fixedprice = rest.fixedprice
      const currency = rest.currency
      let payload: Partial<IPricing> = {
        price: newPrice,
        percentage,
        fixedprice,
        currency,
      }
      if (price?.parent_uid || !supplier) {
        await updatePricing(uid, payload)
        onChange(price)
      } else {
        const res = await createSubpricing(uid, supplier, payload)
        const updatedPricing = { ...price, uid: res.uid, parent_uid: uid }
        onChange(updatedPricing)
      }
      toast.success("Price has been updated")
    } catch (error) {
      toast.error("Price failed to update")
    }
  }

  const debouncedHandler = debounce(handleChange, 1000)

  if (readOnly) {
    return (
      <div className="relative grid grid-cols-12 gap-2 py-2 text-xs">
        <div className="col-span-4">
          <TagsViewer shownAmount={9999} tags={tags} />
        </div>
        <div className="col-span-2">
          <p>{item.fixedprice ? "Fixed price" : "Maximum price"}</p>
        </div>
        <div className="col-span-3">
          <p>
            {formatValue(
              formatTypeValues(
                {
                  value: item.price / 100,
                  locale: space?.locale ?? "en-US",
                  currency: item?.currency ?? space?.currency ?? "EUR",
                },
                "money",
              ),
            )}
          </p>
        </div>
        <div className="col-span-2">
          <p>{item.percentage}%</p>
        </div>
        <div className="col-span-1">
          <></>
        </div>
      </div>
    )
  }

  const priceDiff = item.price - (parent?.price ?? 0)
  const percentageDiff = item.percentage - (parent?.percentage ?? 0)

  return (
    <div className="grid grid-cols-12 items-center gap-2 border-b px-6 py-4 text-xs">
      <div className={`col-span-4 flex items-center gap-2`}>
        {!supplier && <AddTagButton source={item.uid} onSelect={tagsUpdated} />}
        <TagsViewer
          shownAmount={9999}
          tags={tags}
          source={item.uid}
          onRemove={!supplier && !readOnly ? tagsUpdated : undefined}
        />
      </div>
      <div className="col-span-2">
        <DropdownGeneric
          collection={["Fixed price", "Maximum price"]}
          renderItem={(value) => (
            <DropdownItem
              onClick={() =>
                debouncedHandler({
                  ...item,
                  fixedprice: value.item === "Fixed price",
                })
              }
              className="text-xs"
              title={value.item}
              selected={
                (value.item === "Fixed price" && item.fixedprice) ||
                (value.item === "Maximum price" && !item.fixedprice)
              }
              icon={faCheck}
            />
          )}
          button={
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faCaretDown} className="text-gray-300" />
              <p>{item.fixedprice ? "Fixed price" : "Maximum price"}</p>
            </div>
          }
        />
      </div>
      <div className="col-span-3 flex items-center">
        <MoneyInput
          className="p-2"
          value={{ value: item.price, currency: item.currency }}
          onChange={({ value: price, currency }) =>
            debouncedHandler({ ...item, price, currency })
          }
        />
        {parent && priceDiff !== 0 && (
          <div
            className={`ml-1 flex items-center gap-2 text-xs ${priceDiff > 0 ? "text-green-400" : "text-red-400"}`}
          >
            <FontAwesomeIcon icon={priceDiff > 0 ? faCaretUp : faCaretDown} />
            <p>{Math.abs(priceDiff / 100).toFixed(2)}</p>
          </div>
        )}
      </div>
      <div className="col-span-2 flex items-center">
        <PercentageInput
          value={item.percentage ?? parent?.percentage ?? 0}
          onChange={(percentage) => debouncedHandler({ ...item, percentage })}
        />

        {parent && percentageDiff !== 0 && !isNaN(percentageDiff) && (
          <div
            className={`ml-1 flex items-center gap-2 text-xs ${percentageDiff > 0 ? "text-green-400" : "text-red-400"}`}
          >
            <FontAwesomeIcon
              icon={percentageDiff > 0 ? faCaretUp : faCaretDown}
            />
            <p>{Math.abs(percentageDiff)}%</p>
          </div>
        )}
      </div>
      <div className="col-span-1 flex items-center justify-center">
        {!!item?.uid && (
          <button
            onClick={() => remove(item.uid)}
            className="hover:text-red-400"
          >
            <FontAwesomeIcon size="xl" icon={faTrashCan} />
          </button>
        )}
      </div>
    </div>
  )
}
