import { IPricing } from "@/features/candidateflow/types"
import { faChevronDown } from "@awesome.me/kit-44b29310a6/icons/classic/solid"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Combobox, Transition } from "@headlessui/react"
import { Fragment, useState } from "react"

interface ICategoryInput {
  item: IPricing
  pricings: IPricing[]
  categories: any[]
  onChange(pricing: IPricing & { isNew: boolean }): void
  readOnly?: boolean
}

export const CategoryInput = ({
  item,
  categories,
  pricings,
  onChange,
  readOnly,
}: ICategoryInput) => {
  const [query, setQuery] = useState<string>("")

  let filteredCollection = []

  if (query !== null && categories?.length) {
    filteredCollection =
      query === ""
        ? categories
        : categories.filter(({ name }) =>
            name
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, "")),
          )
  }

  return (
    <Combobox
      disabled={readOnly}
      value={item}
      onChange={(value: IPricing & { isNew: boolean }) =>
        onChange({ ...item, name: value.name, isNew: value.isNew })
      }
    >
      {({ disabled }) => {
        return (
          <div className="relative">
            {!disabled && !readOnly ? (
              <>
                <Combobox.Input
                  className="w-full text-xs leading-5 focus:bg-transparent focus:outline-none"
                  displayValue={() => item.name}
                  onChange={(event) => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                  <FontAwesomeIcon icon={faChevronDown} />
                </Combobox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  afterLeave={() => setQuery("")}
                >
                  <Combobox.Options className="max-h-70 absolute z-50 mt-1 w-full overflow-auto rounded-xl bg-white py-1 text-xs shadow-lg">
                    {query !== "" &&
                    !categories.find(
                      ({ name }) => name.toLowerCase() === query.toLowerCase(),
                    ) ? (
                      <Combobox.Option
                        className={({ active }) =>
                          `relative cursor-pointer select-none px-4 py-2 ${active ? "bg-blue-600 text-white" : ""}`
                        }
                        value={{ name: query, isNew: true }}
                      >
                        Create &quot;{query}&quot;
                      </Combobox.Option>
                    ) : (
                      <></>
                    )}
                    {filteredCollection.map(({ name }, i) => (
                      <Combobox.Option
                        key={i}
                        hidden={!!pricings?.find((a) => a.name === name)}
                        className={({ active }) =>
                          `relative cursor-pointer select-none px-4 py-2 ${active ? "bg-blue-600 text-white" : "text-primary"}`
                        }
                        value={{ name, isNew: false }}
                      >
                        <div className="flex items-center">{name}</div>
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Transition>
              </>
            ) : (
              <p className="text-xs">{item.name ?? ""}</p>
            )}
          </div>
        )
      }}
    </Combobox>
  )
}
