import { FetchClient } from "@/core/client/fetch_client"
import { getService } from "hooks/useStartup"
import { ResponseModel } from "@/core/generated/api/models/response_model"
import { ResponseSerializer } from "@/core/generated/api/response_serializer"
import { CVLink } from "@/modules/flowcase/models/cvs/cv_link"
import { DownloadCVArgs } from "@/modules/flowcase/models/args/download_cv_args"

export const downloadCV = async ({
  cv,
  user,
  lang,
  filetype,
  template,
}: DownloadCVArgs): Promise<CVLink | null> => {
  const client: FetchClient = getService(FetchClient)
  let response: ResponseModel | null = null
  try {
    response = await client.getAsync(
      `/flowcase/cvs/${cv}/download/${user}/${lang}/${filetype}${template ? `/${template}` : ""}`,
    )
    if (response !== null) {
      const model: CVLink = ResponseSerializer.deserialize<CVLink>(response)
      return model
    }
    return null
  } catch (error) {
    console.error("Failed to fetch master data", error)
  }
}
